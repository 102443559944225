<template>
  <div id="about-credits">
    <h1>{{ content.title }}</h1>
    <Toc v-if="content.generateToc" :items="tocItems"></Toc>
    <div class="cms-content" v-html="content.content"></div>
  </div>
</template>

<script>
import Toc from "@/components/base/Toc"
import StaticContent from "@/mixins/StaticContent.vue"
import { gql } from "apollo-boost"

export default {
  name: "Credits",
  components: {
    Toc
  },
  mixins: [StaticContent],
  apollo: {
    content: {
      query: gql`
        query getCredits {
          credit {
            data {
              attributes {
                title
                content
                generateToc: toc
              }
            }
          }
        }
      `,
      update: (data) => data.credit.data.attributes
    }
  }
}
</script>
